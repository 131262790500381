/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      #{$ldirection}: 0;
    }
    &--positioned-stretched {
      position: absolute;
      z-index: auto;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
  }
  &__line {
    // make sure we do not pick up flex alignment rules
    display: block;
    padding-bottom: 12px;
    // compensate for the WYSIWYG adding extra paragraphs
    p {
      margin: 0;
      padding: 0;
    }
    &--link {
      padding: 0 12px 12px;
      span {
        padding: 0 20px;
      }
    }
  }
  &__link {
    padding-bottom: 1em;
  }
  &__links {
    display: block;
    min-height: 1.5em;
    padding-bottom: 12px;
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &.basic-tout,
  &.video-tout {
    &__content {
      display: block;
    }
    .content-block__line--link {
      display: inline-block;
    }
  }
  // Text Alignment
  &.text-align--#{$ldirection} {
    text-align: $ldirection;
  }
  &.text-align--center {
    text-align: center;
  }
  &.text-align--#{$rdirection} {
    text-align: $rdirection;
  }
  // button styles
  a,
  .button {
    &.content-block-button {
      &--primary {
        @include content-block-btn-primary;
        -webkit-text-stroke: 0.3px;
        @include breakpoint($medium-up) {
          -webkit-text-stroke: 0;
        }
      }
      &--secondary {
        @include content-block-btn-secondary;
        -webkit-text-stroke: 0.2px;
        @include breakpoint($medium-up) {
          -webkit-text-stroke: 0;
        }
      }
      &--tertiary {
        @include content-block-btn-tertiary;
        -webkit-text-stroke: 0.2px;
        @include breakpoint($medium-up) {
          -webkit-text-stroke: 0;
        }
      }
      &--inactive {
        @include content-block-btn-inactive;
      }
    }
  }
  .text-color {
    color: $off-black;
    &--white {
      color: $color-white;
    }
    &--black {
      color: $color-black;
    }
    &--light-grey {
      color: $color-light-gray;
    }
    &--mid-grey,
    &--grey {
      color: $color-mid-gray;
    }
    &--dark-grey {
      color: $color-darker-gray;
    }
    &--green {
      color: $color-green;
    }
    &--light-green {
      color: $color-light-green;
    }
    &--ivory {
      color: $color-ivory;
    }
    &--red {
      color: $color-red;
    }
  }
}

.style--bare {
  .content-block__line--eyebrow & {
    @include text-style--eyebrow;
  }
  .content-block__line--header & {
    @include text-style--section-title;
  }
  .content-block__line--content & {
    @include text-style--body;
  }
}

.cta--bare {
  @include cr18-btn-primary;
}

// vertical_rules_v1 option helpers
.container-vertical-top-margin {
  &--large {
    margin-top: 60px;
    @include breakpoint($landscape-up) {
      margin-top: 96px;
    }
  }
  &--small {
    margin-top: 40px;
    @include breakpoint($landscape-up) {
      margin-top: 48px;
    }
  }
}
.container-vertical-bottom-margin {
  &--large {
    margin-bottom: 60px;
    @include breakpoint($landscape-up) {
      margin-bottom: 96px;
    }
  }
  &--small {
    margin-bottom: 40px;
    @include breakpoint($landscape-up) {
      margin-bottom: 48px;
    }
  }
}
